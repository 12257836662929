import { useDialog } from 'react-app/components/DialogProvider';
import Stack from '@mui/material/Stack';
import HeroBanner from 'components/HeroBanner';
import HeroBannerLogo from 'components/HeroBanner/HeroBannerLogo';
import HeroBannerHeadline from 'components/HeroBanner/HeroBannerHeadline';
import HeroBannerButton from 'components/HeroBanner/HeroBannerButton';
import { logo_holzfabrik_mono, factory_home_banner } from 'config/images';
import { appData } from 'config/data.cjs';

const { isFactory } = appData;

const LogoProps = {
	width: {xs: 120, tablet: 140, md: 240, desktop: 260}
};

const HeroBannerProps = {
	overlay: true,
	image: {
		...factory_home_banner
	},
	sx: {
		'&&': {
			maxHeight: {xs: 220, sm: 240, md: 220, desktop: 250},
			minHeight: {xs: 220, sm: 240, md: 220, desktop: 250}
		}
	}
};

const HeroBannerHeadlineProps = {
	sx: {
		maxWidth: 1000,
		paddingX: {lg: 24, xl: 12}
	}
};

export default function FactoryHomePageHeroBanner(props) {
	const { openDialog } = useDialog();

	return (
		<HeroBanner {...HeroBannerProps}>
			<HeroBannerLogo ImgProps={logo_holzfabrik_mono} LogoProps={LogoProps} justifyContent="space-around" gap={{xs: 1, md: 2}}>
				<Stack gap={{xs: 1, md: 2, lg: 3}}>
					<HeroBannerHeadline
						{...HeroBannerHeadlineProps}
						noTextStroke
						gutterTop={false}
						title="Hochwertige Einzelstücke aus Holz und Metall"
						component="h1"
					/>
					<Stack direction="row" gap={{xs: 1, md: 2, lg: 3}} margin="auto">
						<HeroBannerButton
							color={isFactory ? 'hf-brown' : 'hw-orange'}
							href={isFactory ? 'https://shop.holzfabrik-erzgebirge.de' : 'https://www.holzfabrik-erzgebirge.de'}
						>
							{isFactory ? 'Online-Shop' : 'www.holzfabrik-erzgebirge.de'}
						</HeroBannerButton>
						{isFactory && (
							<HeroBannerButton
								onClick={openDialog}
								data-src="/data/Flyer_Holzfabrik_062024.pdf"
								data-title="Holzfabrik Erzgebirge Info-Flyer"
								color="default"
							>
								mehr Infos
							</HeroBannerButton>
						)}
					</Stack>
				</Stack>
			</HeroBannerLogo>
		</HeroBanner>
	);
}
